<template>
  <div>
    <v-dialog v-model="TableStateDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          Table State Management
        </v-card-title>
        <v-card-text>
          Here you can configure the State Management Principle of the Table.
        </v-card-text>
        <v-card-text>
         <FieldConfig @UpdateField="UpdateField" @Onepropemit="Onepropemit" @UpdateRelated="UpdateRelated"
            :CurrentEntity="CurrentEntity"
          :SystemEntities="SystemEntities" :field="EditedStatusField" :NewField="false" :System="System"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelTableStateDialog()">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="UpdateStatemanagement()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="400" v-model="LiaisonTopicDialog">
             <v-card >
                       <v-card-title class="recordtoolbar white--text">
                         Configure Liaison Portal
                       </v-card-title>
                       <v-card-text>
                         Configure the Entity as a Liaison portal item, to open up liaison portal between Guest Dashboard, and Internal Pipelines.
                       </v-card-text>
                       <v-card-text>
                         <v-switch v-model="CurrentEntity.HasLiason" label="Turned On"/>
                         <v-text-field v-if="CurrentEntity.HasLiason" label="Topic Name (must be unique to Table)" v-model="CurrentEntity.LiaisonTopicName"/>
                         <v-text-field v-if="CurrentEntity.HasLiason" label="Topic Icon" v-model="CurrentEntity.LiasonTopicIcon"/>
                         <v-select v-if="CurrentEntity.HasLiason" label="Client Field" return-object :items="CurrentEntityUserLookupFields" item-text="DisplayName" v-model="CurrentEntity.ClientLookupField"/>
                       </v-card-text>
                       <v-card-actions>
                         <v-btn>Cancel</v-btn>
                         <v-spacer></v-spacer>
                         <v-btn :disabled="!CurrentEntity.HasLiason" @click="UpdateClientLiaisonPortal()">Save</v-btn>
                       </v-card-actions>
                </v-card>
            </v-dialog>
    <v-dialog max-width="400" v-model="NewRelationshipFieldDialog">
        <v-card>
            <v-card-title>
                New Relationship Field
            </v-card-title>
            <v-card-subtitle>
                {{LookupFieldDisplay(NewRelationshipField)}}
            </v-card-subtitle>
            <v-card-text>
                <v-select :items="SystemEntities" item-text="id" label="Parent" v-model="NewRelationshipField.RelatedBuildID"
                 @change="UpdateRelated(NewRelationshipField,NewRelationshipField.RelatedBuildID)"/>
                 <v-select :items="SystemEntities" item-text="id" label="Child" v-model="NewRelationshipField.EntityID"
                />
                 <v-text-field label="Display Name" v-model="NewRelationshipField.DisplayName"/>
            </v-card-text>
            <v-card-text>
                
                <FieldValueEditerComponent v-if="FinalNewRelationshipField" @UpdateEditableField="UpdateEditableField" :Record="SampleRecord"
                :field="FinalNewRelationshipField" @onPhotoFileselected="onPhotoFileselected"
                :SystemEntities="SystemEntities" :RelatedObj="{}" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                @UploadFileSelect="UploadFileSelect"
            </v-card-text>
            <v-card-actions>
                <v-btn @click="CancelNewRelationshipField()" dark color="warning">
                    Cancel
                </v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn @click="SaveNewRelationshipField(NewRelationshipField)" dark color="success">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

       
      <!-- <v-list v-if="!ActiveEntity && DataNavlists && DataNavlists.length > 0">
          <v-list-item :to="dir.Path" class="detailslistoutline" v-for="dir in DataNavlists" :key="dir.itemObjKey">
              <v-list-item-avatar>
                      <v-icon>{{dir.icon}}</v-icon>
                  </v-list-item-avatar>
              <v-list-item-content>                  
                  <v-list-item-title>
                    {{dir.title}}
                  </v-list-item-title>
              </v-list-item-content>
          </v-list-item>
      </v-list> -->
      <!-- <ModuleValidator  v-if="!ActiveEntity" :SingleRecordNavMenuItem="SingleRecordNavMenuItem" :ActiveDB="ActiveDB" :System="System" :SummmaryOnly="true"
      @InvokeConfigureAction="InvokeConfigureAction"
              :Module="{Name: 'Database Builder'}" :SystemEntities="RelevantAppEntities" :Directories="Directories" :SitePages="SitePages"
                :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides"/> -->
      <v-list v-if="!ActiveEntity">
          <v-list-item @click="ActivateActiveEntity(ent)" class="detailslistoutline" v-for="ent in RelevantAppEntities" :key="ent.itemObjKey">
              <v-list-item-avatar>
                      <v-icon>{{ent.Table_Icon}}</v-icon>
                  </v-list-item-avatar>
              <v-list-item-content>                  
                  <v-list-item-title>
                    {{ent.DisplayName}}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                      {{ent.SingleName}}
                  </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="ent.Plugin">
                  <v-list-item-title style="margin-right: auto;">
                    Plugin Type:
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-icon>
                        {{ent.Plugin.Icon}}
                    </v-icon>
                      {{ent.Plugin.Name}}
                  </v-list-item-subtitle>
              </v-list-item-action>
          </v-list-item>
      </v-list>
       <v-list v-if="!ActiveEntity">
           <v-card-title class="background darken-1 mediumoverline justify-center">
             Available Tables
          </v-card-title>
          <v-card-text>
            These are Tables in your Database that are not assigned to an App. 
            You may assign them here.
          </v-card-text>
          <v-list-item dense v-for="table in AvailableTables" :key="table.itemObjKey">
                <v-list-item-avatar>
                    <v-icon>{{table.Table_Icon}}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    {{table.DisplayName}}
                </v-list-item-content>
                <!-- <v-list-item-action v-if="plug.Active">
                  <v-list-item-title style="margin-right: auto;">
                    Uses:
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    
                      {{plug.Tables}}
                  </v-list-item-subtitle>
              </v-list-item-action> -->
                <v-list-item-action>
                    <v-btn @click="ConfirmAssignTabletoActiveApp(table)">Activate</v-btn>
                </v-list-item-action>
            </v-list-item>
       </v-list>
      <v-list v-if="!ActiveEntity">
           <v-card-title class="background darken-1 mediumoverline justify-center">
             Available Table Plugins
          </v-card-title>
          <v-card-text>
            <v-list-item dense v-for="plug in TablePlugins" :key="plug.itemObjKey">
                <v-list-item-avatar>
                    <v-icon>{{plug.Icon}}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    {{plug.Name}}
                </v-list-item-content>
                <v-list-item-action v-if="plug.Active">
                  <v-list-item-title style="margin-right: auto;">
                    Uses:
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    
                      {{plug.Tables}}
                  </v-list-item-subtitle>
              </v-list-item-action>
                <v-list-item-action>
                    <v-btn @click="PickPlugin(plug)">Activate</v-btn>
                </v-list-item-action>
            </v-list-item>
        <!-- <RAPluginCarousel :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="TablePlugins" :tab="systemguideelmnt"
        :ListPreviewOnly="false" :Squished="true" :PluginPicker="true" @PickPlugin="PickPlugin"
            /> -->
          </v-card-text>
          </v-list>
          <div  v-if="ActiveEntity">
          <v-list class="background darken-1 justify-center">
           <v-btn :top="PDFBuilderActive" absolute style="z-index:2;" dark @click="CancelActiveEntity()">Back</v-btn>
            <v-list-item v-if="!PDFBuilderActive">
               
                <v-list-item-content style="text-align: center;">
                    <v-list-item-title :class="MobileView ? 'overline' : 'mediumoverline'">
                        <v-icon :style="MobileView ? 'vertical-align: top;' : ''" :small="MobileView" v-if="CurrentEntity.Table_Icon">
                        {{CurrentEntity.Table_Icon}}
                    </v-icon>
                    {{CurrentEntity.DisplayName}}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </v-list>
          
          <div v-if="!EntityConfigView">
            <!-- Still need 
            1. Portal Item, Has Wiki, Table State, Basic Info
            2. Library Relationships
            3. PDF Builder -->
            <!-- if I put 1:1 like pd or dir in main, also put basic etc in there..then..lib rel under rel -->
            <!-- mainly we gtta figure out this shit basically place items then figure out what's best -->
            <v-expansion-panels>
                <v-expansion-panel v-for="main in EntityConfigOptions"
                :key="main.itemObjKey">
                    <v-expansion-panel-header>
                        <v-list-item dense
                        
                        >
                        <v-list-item-icon>
                                <v-icon>{{main.Icon}}</v-icon><v-badge color="error" v-if="main.notifications" overlap >
                                    <span slot="badge"> {{main.notifications}}</span>
                                    </v-badge>
                                </v-list-item-icon>
                                <v-list-item-title>{{main.Name}}
                                </v-list-item-title>
                        </v-list-item>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-card width="100%" tile flat>
                            <v-card-text >
                                <v-layout row class="justify-center">
                                    <v-icon color="lime" size="200">
                                        {{main.Icon}}
                                    </v-icon>
                                </v-layout>
                                <v-layout row class="justify-center">
                                    <div style="font-size: 1.3em;" v-html="main.Description"/>
                                </v-layout>
                            </v-card-text>
                        </v-card>
                        <v-layout row class="justify-center" v-if="main.Children">                
                            <v-col style="flex-grow: 0!important;" v-for="child in main.Children" :key="child.itemObjKey">
                                <v-tooltip bottom max-width="200" >
                                <template v-slot:activator="{ on, attrs }">
                                <v-card v-on="on" elevation="4" :color="child.Color ? child.Color: 'red'" @click="ActivateEntityConfigView(child)"
                                :height="150" :width="150" tile dark>
                                    <v-card-title class="justify-center" style="padding-bottom: 0px;">
                                        <v-icon size="70">{{child.Icon}}</v-icon>
                                    </v-card-title>
                                    <v-card-title class="font-weight-thin justify-center white--text" style="padding-top: 10px;text-align: center;font-size: 0.9em;">
                                        {{child.Name}}
                                    </v-card-title>
                                    </v-card>
                                </template>
                                <span v-html="child.Description">
                                </span>
                                </v-tooltip>
                            </v-col>
                        </v-layout>
                            <v-card-actions class="justify-end">
                                <v-btn outlined v-if="main.DocumentationRoute"
                                :to="main.DocumentationRoute">
                                    Read More
                                </v-btn>
                                <v-btn outlined color="pink"
                                @click="ActivateEntityConfigView(main)">
                                    Configure
                                </v-btn>
                            </v-card-actions>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
             <!-- <v-list-item dense @click="ActivateEntityConfigView(main)"
            v-for="main in EntityConfigOptions"
            :key="main.itemObjKey" style="padding-left:10px;"
            
            >
             <v-list-item-icon>
                    <v-icon>{{main.Icon}}</v-icon><v-badge color="error" v-if="main.notifications" overlap >
                        <span slot="badge"> {{main.notifications}}</span>
                        </v-badge>
                    </v-list-item-icon>
                    <v-list-item-title>{{main.Name}}
                    </v-list-item-title>
            </v-list-item> -->
          </div>
          <div v-else-if="EntityConfigView">
            <div v-if="EntityConfigView.DataViewName === 'General'">
              
            <v-list>  
            <v-list-item @click="EditTableState()"  class="listoutline">
                <v-list-item-title  class="subtleoverline">
                Manage Table State
                </v-list-item-title>
                <v-list-item-action>
                    <v-icon color="warning">mdi-power-settings</v-icon>
                </v-list-item-action>
                </v-list-item>
                <v-list-item @click="ActivateLiaisonTopicDialog()" class="listoutline">
                <v-list-item-title  class="subtleoverline ">
                Portal Item
                </v-list-item-title>
                <v-list-item-action>
                    <v-icon color="warning">mdi-comment-outline</v-icon>
                </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="HasDirectory" @click="EditTableState()" :to="'/DirectoryBuilder/'+CurrentEntity.id" class="listoutline">
                    <v-list-item-title  class="subtleoverline ">
                Directory
                </v-list-item-title>
                <v-list-item-action>
                    <v-icon color="warning">mdi-view-list</v-icon>
                </v-list-item-action>
                </v-list-item>
                </v-list-item>
            </v-list>  
              <QuickEditComponent :System="System" :SystemEntities="SystemEntities" :Record="ActiveEntity" :QuickEditSections="QuickEditSections"
            @UpdateContentEditableField="UpdateContentEditableField" @UploadFileSelect="UploadFileSelect"
            /> 
            </div>
            <div v-if="EntityConfigView.DataViewName === 'Fields'">
                
              <DataTableFieldBuilder :SampleRecord="SampleRecord"
                 :CurrentEntity="SelectedEntity" :AllowedFieldTypes="AllFieldTypes"
                 :RelatedObj="{}" :EntityCollectionRef="EntityCollectionRef"
                :SystemEntities="SystemEntities" :System="System"
                />
             
        </div>
        <div v-if="EntityConfigView.DataViewName === 'Data Cabinets'">
            
            <!-- Data Single Related used to do SubCollections but screw it -->
           <v-layout row class="justify-center">                
                <v-col style="flex-grow: 0!important;" v-for="child in EntityConfigView.Children" :key="child.itemObjKey">
                    <v-tooltip bottom max-width="200" >
                    <template v-slot:activator="{ on, attrs }">
                    <v-card v-on="on" elevation="4" :color="child.Color ? child.Color: 'red'" @click="ActivateEntityConfigView(child)"
                    :height="150" :width="150" tile dark>
                        <v-card-title class="justify-center" style="padding-bottom: 0px;">
                            <v-icon size="70">{{child.Icon}}</v-icon>
                        </v-card-title>
                        <v-card-title class="font-weight-thin justify-center white--text" style="padding-top: 10px;text-align: center;font-size: 0.9em;">
                            {{child.Name}}
                        </v-card-title>
                        </v-card>
                    </template>
                    <span v-html="child.Description">
                    </span>
                    </v-tooltip>
                </v-col>
            </v-layout>
        </div>
        <div v-if="EntityConfigView.DataViewName === 'Data Single Gallery'">
            
            <DataTableSubcolBuilder :SampleRecord="SampleRecord" :RADB="RADB"
                 :CurrentEntity="SelectedEntity" :DataViewName="EntityConfigView.DataViewName"
                 :RelatedObj="{}" :EntityCollectionRef="EntityCollectionRef"
                :SystemEntities="SystemEntities" :System="System"
                />
        </div>
        <div v-if="EntityConfigView.DataViewName === 'Data Single Document Register'">
            
             <DataTableSubcolBuilder :SampleRecord="SampleRecord" :RADB="RADB"
                 :CurrentEntity="SelectedEntity" :DataViewName="EntityConfigView.DataViewName"
                 :RelatedObj="{}" :EntityCollectionRef="EntityCollectionRef"
                :SystemEntities="SystemEntities" :System="System"
                />
        </div>
        <div v-if="EntityConfigView.DataViewName === 'Data Single SubCollection'">
            
             <DataTableSubcolBuilder :SampleRecord="SampleRecord" :RADB="RADB"
                 :CurrentEntity="SelectedEntity" :DataViewName="EntityConfigView.DataViewName"
                 :RelatedObj="{}" :EntityCollectionRef="EntityCollectionRef"
                :SystemEntities="SystemEntities" :System="System"
                @Onepropemit="Onepropemit" @Twopropemit="Twopropemit" @Threepropemit="Threepropemit"
                />
        </div>
        <div v-if="EntityConfigView.DataViewName === 'Data Relationships'">
            
            <v-list class="transparent" v-if="!EditedRelationshipField">
            <v-list-item>
                <v-btn outlined @click="AddNewRelationshipField()">
                    <v-icon>mdi-plus
                    </v-icon>
                Add New
                </v-btn>
            </v-list-item>
            <v-list-item @click="ActivateEditedRelationshipField(fieldobj)" v-for="fieldobj in RelatedTableFields" :key="fieldobj.itemObjKey">
                <v-list-item-content>
                    <v-list-item-title>
                {{fieldobj.DisplayName}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{LookupFieldDisplay(fieldobj)}}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            </v-list>
            <v-list class="transparent" v-if="EditedRelationshipField">
                 <FieldConfig @UpdateField="UpdateField" @Onepropemit="Onepropemit" @UpdateRelated="UpdateRelated"
                 :CurrentEntity="CurrentEntity" @RefreshField="RefreshField" v-if="!RefreshingNewField"
                :SystemEntities="SystemEntities" :field="EditedRelationshipField" :NewField="false" :System="System"
                />
            </v-list>            
            <!-- technically here related tables also apply.  -->
        </div>
        <div v-if="EntityConfigView.DataViewName === 'Data Views'">
            
            <v-layout row class="justify-center">                
                <v-col style="flex-grow: 0!important;" v-for="child in EntityConfigView.Children" :key="child.itemObjKey">
                    <v-tooltip bottom max-width="200" >
                    <template v-slot:activator="{ on, attrs }">
                    <v-card v-on="on" elevation="4" :color="child.Color ? child.Color: 'red'" @click="ActivateEntityConfigView(child)"
                    :height="150" :width="150" tile dark>
                        <v-card-title class="justify-center" style="padding-bottom: 0px;">
                            <v-icon size="70">{{child.Icon}}</v-icon>
                        </v-card-title>
                        <v-card-title class="font-weight-thin justify-center white--text" style="padding-top: 10px;text-align: center;font-size: 0.9em;">
                            {{child.Name}}
                        </v-card-title>
                        </v-card>
                    </template>
                    <span v-html="child.Description">
                    </span>
                    </v-tooltip>
                </v-col>
            </v-layout>
        </div>
        <div v-if="EntityConfigView.DataViewName === 'Data Table View'">
            <TableMain :System="System" :SystemEntities="SystemEntities"
            :ProvidedEntityid="CurrentEntity.id" 
            :UnreadNotifications="UnreadNotifications"
            />
        </div>
        <div v-if="EntityConfigView.DataViewName === 'Data Single View'">
            <DataSingleRender :SampleOnly="true"  :System="System" :SystemEntities="SystemEntities"
            :MyActivitiesArray="MyActivitiesArray" :UnreadNotifications="UnreadNotifications"
            :EntityCollectionRef="EntityCollectionRef"
            :CurrentEntity="CurrentEntity" :CanEdit="true" :RouteID="RouteID" :SystemPhotoLibraries="SystemPhotoLibraries"
            />
             <!-- <DataSingleViewComponent :System="System" :SystemEntities="SystemEntities"
            :ProvidedEntityid="CurrentEntity.id" :Directories="Directories" 
            :UnreadNotifications="UnreadNotifications"
            /> -->
        </div>
        <div v-if="EntityConfigView.DataViewName === 'Data Security'">
            
            <SecurityOverview :System="System" :SystemEntities="SystemEntities" :ActiveEntity="ActiveEntity"
           :Directories="Directories" :RADB="RADB"
          />
        </div>
        <div v-if="EntityConfigView.DataViewName === 'Extensions'">
            
            <v-layout row class="justify-center">                
                <v-col style="flex-grow: 0!important;" v-for="child in EntityConfigView.Children" :key="child.itemObjKey">
                    <v-tooltip bottom max-width="200" >
                    <template v-slot:activator="{ on, attrs }">
                    <v-card v-on="on" elevation="4" :color="child.Color ? child.Color: 'red'" @click="ActivateEntityConfigView(child)"
                    :height="150" :width="150" tile dark>
                        <v-card-title class="justify-center" style="padding-bottom: 0px;">
                            <v-icon size="70">{{child.Icon}}</v-icon>
                        </v-card-title>
                        <v-card-title class="font-weight-thin justify-center white--text" style="padding-top: 10px;text-align: center;font-size: 0.9em;">
                            {{child.Name}}
                        </v-card-title>
                        </v-card>
                    </template>
                    <span v-html="child.Description">
                    </span>
                    </v-tooltip>
                </v-col>
            </v-layout>
        </div>
            <div v-if="EntityConfigView.DataViewName === 'Data Boards'">
                <v-list-item class="subtileoverline">
                    Available Fields
                </v-list-item>
                <v-list-item class="detailslistoutline" dense v-for="field in AvailableBoardFields"
                    :key="field.itemObjKey">
                    <v-list-item-content>
                    <v-list-item-title v-text="field.Name"></v-list-item-title>
                    
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-switch @change="UpdateIsBoard(field)" v-model="field.IsBoard">
                        </v-switch>
                    </v-list-item-action>
                    </v-list-item>
                <v-list-item class="subtileoverline">
                    Board Fields
                </v-list-item>
                <v-list-item class="detailslistoutline" dense v-for="field in ActiveBoardFields"
                    :key="field.itemObjKey">
                    <v-list-item-content>
                    <v-list-item-title v-text="field.Name"></v-list-item-title>
                    
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-switch @change="UpdateIsBoard(field)" v-model="field.IsBoard">
                        </v-switch>
                    </v-list-item-action>
                    </v-list-item>
            </div>
            <div v-if="EntityConfigView.DataViewName === 'Data Workflows'">
                
                <!-- <v-btn @click="ActivatePipelineworkflowdialog()" outilned>Add New</v-btn> -->
                <WorkFlowBuilder  @ToggleAutomationDialog="ToggleWorkflowBuilderDialog"
                :System="System" :SystemEntities="SystemEntities" :CurrentEntity="CurrentEntity"

                />
                <!-- <AutomationWorkflows
                :System="System" :SystemEntities="SystemEntities"
                /> -->
            </div>
            <div v-if="EntityConfigView.DataViewName === 'Data Entity Processes'">
                <PipelineManagement :System="System" :SystemEntities="SystemEntities"
                :ProvidedEntityID="CurrentEntity.id"
                />
            </div>
            <div v-if="EntityConfigView.DataViewName === 'PDF Builder'">
                
                <PDFExportTemplates :System="System" :SystemEntities="SystemEntities"
                :CurrentEntity="CurrentEntity"
                />
            </div>
          </div>
          </div>
          <!-- Data Security -->
        <!-- NEED THEN "Loan Packages" the "Basic" and "Board Options" -->
      
      <v-card-text v-if="SecurityView">
          <SecurityOverview :System="System" :SystemEntities="SystemEntities" :ActiveEntity="ActiveEntity"
           :Directories="Directories" :RADB="RADB"
          />
      </v-card-text>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import RAPluginCarousel from '@/components/WebPages/RenderComponents/RAPluginCarousel';
import SecurityOverview from '@/components/Database/SecurityOverview';
import QuickEditComponent from '@/components/Database/QuickEditComponent'
import ModuleValidator from '@/components/SuiteBuilder/Main/ModuleValidator'
import ContentEditableField from '@/components/Database/ContentEditableField'
import CabinetORSubcolConfig from '@/components/Database/CabinetORSubcolConfig';
import DataTableFieldBuilder from '@/components/SuiteBuilder/SuiteApps/Settings/DBBuilder/DataTableFieldBuilder';	
import DataTableSubcolBuilder from '@/components/SuiteBuilder/SuiteApps/Settings/DBBuilder/DataTableSubcolBuilder';	
import FieldConfig from '@/components/Database/FieldConfig';	
import FieldValueEditerComponent from '@/components/Database/FieldValueEditerComponent';	
import WorkFlowBuilder from '@/components/SuitePlugins/Automations/WorkFlowBuilder'
import TableMain from '@/components/Database/TableMain';
import DataSingleViewComponent from '@/components/Database/DataSingleViewComponent.vue'
import LandingPageSingle from '@/components/WebPages/LandingPageSingle';
import DataSingleRender from '@/components/SuiteBuilder/SuiteApps/Settings/DBBuilder/DataSingleRender';
import PipelineManagement from '@/components/SuitePlugins/Pipelines/PipelineManagement'
import PDFExportTemplates from '@/components/SuitePlugins/PDFBuilder/PDFExportTemplates'
export default {
    props: ['System','SystemEntities','RADB','Directories','ActiveSession','SystemGuides','UnreadNotifications','SystemPhotoLibraries',
    'MyActivitiesArray','UnreadNotifications'],
    components: {RAPluginCarousel,SecurityOverview,QuickEditComponent,ModuleValidator,CabinetORSubcolConfig,WorkFlowBuilder,PDFExportTemplates,
    ContentEditableField,DataTableFieldBuilder,DataTableSubcolBuilder,FieldConfig,FieldValueEditerComponent,TableMain,DataSingleViewComponent,LandingPageSingle,DataSingleRender,PipelineManagement},
    data() {
        return {
            AllFieldTypes: ['Single Line Text','Multiple Lines Text','Common Field','Boolean','Option Set','Radio Group',
        'Date','AutoNumber Field','Number Field','Single File Upload','Rollup Calculation','Calculated Field',
        'Map Location','Shared Doc Link','Lookup','Parent Field','Route Path','Social Lookup','Custom Object'],
            EditingSingleView: false,
            TableStateDialog: false,
            EditedStatusField: '',
            StatusField: '',
            LiaisonTopicDialog: false,
            WorkflowBuilderDialog: false,
            EditedRelationshipField: '',
            RefreshingNewField: false,
            NewRelationshipFieldDialog: false,
            NewRelationshipField: {
                Type: 'Lookup',
                Name: '',
                DisplayName: '',
            },
            DefaultNewRelationshipField: {                
                Type: 'Lookup',
                Name: '',
                DisplayName: '',
            },
            InvoicetoUserCompany: false,
            LoanPackages: [
                {Name: 'Month End',Description: 'Lorem ipsum borther mother and son',Interest: 0.69,Period: 1},
                {Name: '2 Months',Description: 'Lorem ipsum borther mother and son',Interest: 0.79,Period: 2},
                {Name: '3 Months',Description: 'Lorem ipsum borther mother and son',Interest: 0.93,Period: 3}
            ],
            NewTableOption: '',
            SampleTableOption: '',
            subcolstep: 1,
            systemguideelmnt: {			
              HideGuidesCarousel: false,						
                RowColor: '#00ffff',									
                Name: 'System Guide',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,		
                Elevation: 6,
                BoxTransparency: '',
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
            SampleRecord: {},
            SecurityView: false,
            ActiveEntity: '',
            ExporttoPDFFunctions: [],
        }
    },	
    computed:{
        AvailableTables(){
            return this.SystemEntities.filter(ent => {
                return !ent.Primary_Appid && !ent.ActiveApps || ent.ActiveApps && !ent.ActiveApps.includes(this.ActiveSuiteApp.id)
                //you know what? perhaps these apps can in fact be shared continuosly am I right?
            })
        },
        PDFBuilderActive(){
            return this.$store.state.PDFBuilderActive
        },
        AvailableBoardFields(){
            return this.EntityOptionSets.filter(field => {
                return !field.IsBoard
            })
        },
        ActiveBoardFields(){
            return this.EntityOptionSets.filter(field => {
                return field.IsBoard
            })
        },
        EntityOptionSets(){
            return this.CurrentEntity.AllFields.filter(field => {
                return field.Type === 'Option Set' && field.id !== 'Status'
            })
        },
        RouteID(){
            return process.env.VUE_APP_RA_SYSTEM_ID
        },
        EntityConfigOptions(){
            return this.$store.state.EntityConfigOptions
        },
        // FinalNewRelationshipFieldPrimaryName(){
        //     let name = ''
        //     if(this.NewRelationshipField && this.NewRelationshipField.RelatedBuildID){
        //         let ent = this.SystemEntities.find(obj => obj.id === )
        //     }
        // },
        FinalNewRelationshipField(){
            return this.NewRelationshipField && this.NewRelationshipField.RelatedBuildID && this.NewRelationshipField.EntityID ? 
            {
                //id: this.NewRelationshipField.DisplayName.split(' ').join('_'),
                Name: this.NewRelationshipField.DisplayName.split(' ').join('_'),
                text: this.NewRelationshipField.DisplayName,
                DisplayName: this.NewRelationshipField.DisplayName,
                RelatedBuildID: this.NewRelationshipField.RelatedBuildID,
                RelatedLocation: this.NewRelationshipField.RelatedLocation,
                RelatedRecord: this.NewRelationshipField.RelatedRecord,
                LookupFieldName: this.NewRelationshipField.LookupFieldName,
                Type: this.NewRelationshipField.Type,
                Array: [],

            } : ''
        },
        RelatedTableFields(){
            return this.DataTableswithLookuptoCurrentEntity.map(ent => {
                return {...ent.RelationshipField,EntityID: ent.id}
            })
        },
        DataTableswithLookuptoCurrentEntity(){
            if(this.CurrentEntity){
                return this.SystemEntities.map(entity => {
                    let entityobject = {
                        id: entity.id
                    }
                    let oncheck = entity.AllFields.find(obj => obj.RelatedBuildID === this.CurrentEntity.id)
                    if(oncheck){
                        entityobject.RelationshipField = {
                            id: oncheck.id,
                            Name: oncheck.Name,
                            DisplayName: oncheck.DisplayName,
                            Type: oncheck.Type,
                            LookupFieldName: oncheck.LookupFieldName
                        }
                        entityobject.RelationshipName = entity.DisplayName+' ('+oncheck.DisplayName+')'
                    }
                    return entityobject
                }).filter(entity => {
                    return entity.RelationshipField
                })
            }
            else{
                return []
            }
        },
        MobileView(){
            return this.$vuetify.breakpoint.xs
        },
        SmallView(){
            return this.$vuetify.breakpoint.sm
        },
        MiniView(){
                return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
            },
        CurrentEntity(){
            return this.$store.state.CurrentEntity
        },
        ConfigEntityID(){
            return this.SelectedEntity.id
        },
        DBRef(){
            return db.collection('Databases').doc(this.$store.state.ActiveSuiteid)
        },
        EntityCollectionRef(){
            return this.DBRef.collection('entities').doc(this.ConfigEntityID)
        },
        EntityConfigView(){
            return this.$store.state.EntityConfigView
        },
        TablePlugins(){
            return this.RAPlugins.filter(plug => {
                return plug.Installation_Tables
            }).map(plug => {
                let plugobj = JSON.parse(JSON.stringify(plug))
                let active = this.AppPluginsStored.find(obj => obj.id === plug.id)
                if(active){
                    console.log('active',active)
                plugobj.Active = Object.assign({},active)
                plugobj.Tables = ''
                plugobj.Active.Installation_Tables.map((table,tableindex) => {
                    let joiner = ''
                    if(tableindex-1+2 < plugobj.Active.Installation_Tables.length && tableindex !== 0){
                        joiner = ', '
                    }
                    plugobj.Tables = plugobj.Tables+joiner+table.DisplayName
                    if(tableindex===0 && tableindex-1+2 < plugobj.Active.Installation_Tables.length){
                       plugobj.Tables = plugobj.Tables+',' 
                    }
                    return table.DisplayName
                })
                }
                return plugobj
            })
        },
        UserCompaniesActive(){
        return this.PluginDataBase && this.PluginDataBase.User_Companies && this.PluginDataBase.User_Companies.Active
      },
      PluginDataBase(){
      return this.$store.state.PluginDataBase
    },
        SortHeaders(){
            return this.EntityHeaders.map(header => {
                return header.id
            })
        },
        EntityHeaders(){
            return this.CurrentEntity && this.CurrentEntity.AllFields ? this.CurrentEntity.AllFields.filter(field => {
                return field.IsHeader
            }) : []
        },
        BoardOptions(){
        return this.EntityHeaders.filter(field => {
          return field.Type === 'Option Set' && field.IsBoard
        })
      },
        SelectedEntity(){
            if(this.NewTableOption){
                let entity = Object.assign({},this.NewTableOption)
                entity.AllFields = entity.Headers
                return entity
            }
            else{
                return this.CurrentEntity
            }
        },
        SystemisRA(){
        return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
        },
        NeedsRASync(){
            return this.$store.state.NeedsRASync
        },
        RAConfigDB(){
           return this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.CurrentEntity.id)           
        },
        HasDirectory(){
            return this.CurrentEntity && this.CurrentEntity.Entity_Type.Name === 'Classified Listing' || 
            this.CurrentEntity && this.CurrentEntity.Entity_Type.Name === 'Inventory Article'
        },
        DataNavlists(){
            if(this.ActiveSession.title === 'Directories'){
            let storeproductentities = this.AppEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name === 'Inventory Article'
            }).map(entity => {
                let menuobj = {
                        title: entity.DisplayName,
                        icon: entity.Table_Icon,
                        ItemPass: true,
                        id: entity.id,
                        IsRoute: true,
                        Path: '/DirectoryBuilder/'+entity.id
                        }
                return menuobj
            })
            let classifiedentities = this.AppEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name === 'Classified Listing'
            }).map(entity => {
                let menuobj = {
                        title: entity.DisplayName,
                        icon: entity.Table_Icon,
                        ItemPass: true,
                        id: entity.id,
                        IsRoute: true,
                        Path: '/DirectoryBuilder/'+entity.id
                        }
                return menuobj
            })
            let datanavlists = storeproductentities.concat(classifiedentities,[
                {
                title: 'Classifieds',
                icon: 'mdi-newspaper-variant-outline',
                ItemPass: this.ActiveSuiteApp.Plugins.find(obj => obj.id === 'Classifieds'),
                IsRoute: true,
                Path: '/DirectoryBuilder/Classifieds'
            },{
                title: 'Group Categories',
                icon: 'mdi-account-group',
                ItemPass: this.ActiveSuiteApp.Plugins.find(obj => obj.id === 'Site_Groups'),
                IsRoute: true,
                Path: '/DirectoryBuilder/Group_Categories'
            },{
                title: 'Group Featured Members',
                icon: 'mdi-account-star-outline',
                ItemPass: this.ActiveSuiteApp.Plugins.find(obj => obj.id === 'Group_Featured_Members'),
                IsRoute: true,
                Path: '/DirectoryBuilder/Group_Featured_Members'
            },{
                title: 'Business Members',
                icon: 'mdi-smart-card',
                ItemPass: this.ActiveSuiteApp.Plugins.find(obj => obj.id === 'Digital_Business_Cards'),
                IsRoute: true,
                Path: '/DirectoryBuilder/Business_Members'
            }
            ])
            return datanavlists.filter(item => {
                return item.ItemPass
            })
            }
        },
        StoreOrderEntity(){
            return this.CurrentEntity && this.CurrentEntity.Entity_Type.Name === 'Store Order'
        },
        StoreProductEntity(){
            return this.CurrentEntity && this.CurrentEntity.Entity_Type.Name === 'Store Product'
        },
        InventoryArticleEntity(){
            return this.CurrentEntity && this.CurrentEntity.Entity_Type.Name === 'Inventory Article'
        },
        ScheduledBooking(){
            return this.CurrentEntity && this.CurrentEntity.Entity_Type.Name === 'Booking Schedule'
        },
        CustomDirectory(){
            return this.CurrentEntity && this.CurrentEntity.Entity_Type.Name === 'Classified Entity'
        },
        CustomCalendar(){
            return this.CurrentEntity && this.CurrentEntity.Entity_Type.Name === 'Calendar'
        },
        TicketTable(){
            return this.CurrentEntity && this.CurrentEntity.Entity_Type.Name === 'Ticketing Table'
        },
        StoreOrderEntity(){
            return this.CurrentEntity && this.CurrentEntity.Entity_Type.Name === 'Store Order'
        },
        QuickEditSections(){
            //WE ALSO CATER FOR DIRECTORIES TO REPLACE SuiteBuilder/SuiteApps/Settings/Directories.vue ???? OR USE THAT DOWN HERE???
          let sect1fields = ['Table_Icon','Description','Has_Wiki']
        //   /'StoreOrderEntity','Precede_with_Quote'(!E-COMMERCE PLUGIN),'Warehouse_Dispatch_After' not sure if we keeping this route...install store order entity when needed right? base template, period right? but yes then config
        //yes don't forget store order is new entiy type
          let basic = [
            {Header: 'Basic',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect1fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
          ]
          let typebased = []
          if(this.ScheduledBooking){
              //Has_Location
              let sect2fields = ['Hide_by_Day_Filter','Guests_Can_Book','Guests_Only','Bookings_have_Dialog']
              typebased = [
                {Header: 'Advanced',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},  
              ]
          }
          if(this.StoreOrderEntity){
              //Has_Location, Precede_with_Quote? !E-COMMERCE PLUGIN
              let sect2fields = ['COD_Payment_Terms']
              typebased = [
                {Header: 'Advanced',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},  
              ]
          }
          if(typebased.length === 0){
              basic[0].Class = 'flex xl12 lg12 md12 sm12 xs12'
          }
          return basic.concat(typebased)
        },
        AppPluginsStored(){
            return this.ActiveSuiteApp && this.ActiveSuiteApp.Plugins ? this.ActiveSuiteApp.Plugins : []
        },
        RelevantAppEntities(){
           //console.log('AppEntities',this.AppEntities)
            return this.AppEntities.filter(ent => {
                if(this.ActiveSession.title === 'Database'){
                    return ent.Entity_Type.Name === 'Standard' || ent.Entity_Type.Name === 'Cash Loan' || ent.Entity_Type.Name === 'Ticketing Table' ||
                    ent.Entity_Type.Name === 'Calendar' || ent.Entity_Type.Name === 'Booking Schedule' 
                }
                // else if(this.ActiveSession.title === 'Calendars'){
                //   return ent.Entity_Type.Name === 'Calendar' || ent.Entity_Type.Name === 'Booking Schedule' 
                // }  
                else if(this.ActiveSession.title === 'Store'){
                  return ent.Entity_Type.Name === 'Store Order' || ent.Entity_Type.Name === 'Sales Quote' || ent.Entity_Type.Name === 'Sales Invoice' 
                }    
                else if(this.ActiveSession.title === 'Directories'){
                  return ent.Entity_Type.Name === 'Classified Entity' || ent.Entity_Type.Name === 'Inventory Article' 
                }   
                else if(this.ActiveSession.title === 'Warehouses'){
                  return ent.Entity_Type.Name === 'Inventory Article' || ent.Entity_Type.Name === 'Store Product' || ent.Entity_Type.Name === 'Store Supplier' || ent.Entity_Type.Name === 'Purchase Invoice'
                  
                }        
            }).map(ent => {
                let entobj = JSON.parse(JSON.stringify(ent))
                this.AppPluginsStored.map(appplug => {
                    if(appplug.Installation_Tables && appplug.Installation_Tables.find(obj => obj.id === entobj.id)){
                        let plugobj = Object.assign({},this.RAPlugins.find(obj => obj.id === appplug.id))
                        entobj.Plugin = plugobj
                    }
                })
                return entobj
            })
        },
        RAPlugins(){
            return this.$store.state.RAPlugins
        },
        AppEntities(){
            return this.SystemEntities.filter(ent => {
                return ent.Primary_Appid === this.ActiveSuiteApp.id
                //DEFINITELY NOT SECONDARY AND SHARED ENTITIES, BUT ALSO SHOULD NOT EVEN REACH THIS POINT FROM SECONDARIES
            })
        },
        ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        ConfigMasterEntityFields(){
            return this.$store.state.EntityMasterFields.map(field => {
                if(field.IsConditionalRender && field.ConditionalOptionField === 'Entity_Type'){
                    //console.log(field.id,field.ConditionalOptions)
                }
                return field
            })
        },
        ConfigMasterEntity(){
            let obj = {
                AllFields: this.ConfigMasterEntityFields
            }
            return obj
        },
    },
    watch: {
        
        EntityConfigView: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){
               //this.ConfigView = newvalue
              }
            }
        },
        ActiveEntity: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue && newvalue){    
                if(newvalue && typeof newvalue.InvoicetoUserCompany !== 'undefined'){
                    this.InvoicetoUserCompany = newvalue.InvoicetoUserCompany
                }      
                if(newvalue && newvalue.Entity_Type && newvalue.Entity_Type.Name === 'Cash Loan'){
                    this.GetExporttoPDFFunctions()
                    }
              }
            },deep: true
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['ReceiveTermsDoc']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.ScrolltoTop()
    },
    methods:{
        ConfirmAssignTabletoActiveApp(table){
            confirm('This will assign the '+table.DisplayName+' to the Current App "'+this.ActiveSuiteApp.Name+'", and also make it unavilable to other Apps unless shared. Are you sure you want to do this?')
            this.AssignTabletoActiveApp(table)
        },
        AssignTabletoActiveApp(table){
            let ActiveApps = table.ActiveApps ? table.ActiveApps : []
            ActiveApps.push(this.ActiveSuiteApp.id)
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc(table.id).update({
                ActiveApps: ActiveApps
            })
        },
        UpdateIsBoard(field){
            this.EntityCollectionRef.collection('AllFields').doc(field.id).update({
                IsBoard: field.IsBoard
            }).then(updtdoc => {
                // console.log('done?')
            })
        },
        RandomColor(){
            let colors = [									
                '#F44336',									
                '#E91E63',									
                '#9C27B0',									
                '#673AB7',									
                '#3F51B5',									
                '#2196F3',									
                '#03A9F4',									
                '#00BCD4',									
                '#009688',									
            ]									
            let ranint = Math.floor(Math.random() * Math.floor(colors.length))	
            return colors[ranint]
        },
        UpdateClientLiaisonPortal(){
        //console.log(this.EntityCollectionRef,this.CurrentEntity)
        
        if(typeof this.CurrentEntity.HasLiason !== 'undefined'){
          this.EntityCollectionRef.update({
            HasLiason: this.CurrentEntity.HasLiason
          })
          let clienttopic = {
              id: this.CurrentEntity.id,
              name: this.CurrentEntity.LiaisonTopicName,
              icon: this.CurrentEntity.LiasonTopicIcon,
              ClientLookupField: this.CurrentEntity.ClientLookupField
            }
            db.collection('Databases').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('LiaisonTopics').doc(clienttopic.id).set(clienttopic).then(res => {
              //console.log('yay we added topic')
            }) 
        }
        if(typeof this.CurrentEntity.LiaisonTopicName !== 'undefined'){
          this.EntityCollectionRef.update({
            LiaisonTopicName: this.CurrentEntity.LiaisonTopicName
          })
        }
        if(typeof this.CurrentEntity.LiasonTopicIcon !== 'undefined'){
          this.EntityCollectionRef.update({
            LiasonTopicIcon: this.CurrentEntity.LiasonTopicIcon
          })
        }
        if(typeof this.CurrentEntity.ClientLookupField !== 'undefined'){
          this.EntityCollectionRef.update({
            ClientLookupField: this.CurrentEntity.ClientLookupField
          })
        }
        this.LiaisonTopicDialog = false
      },
      CancelTableStateDialog(){
      this.TableStateDialog = false
      this.EditedStatusField = ''
      this.$store.commit('SetSocialItemInteractMethodProp',false) 
      this.$store.commit('SetSocialItemInteractMethod','ActivateProcessing')
    },
    UpdateStatemanagement(){
      this.$store.commit('SetSocialItemInteractMethodProp',true) 
      this.$store.commit('SetSocialItemInteractMethod','ActivateProcessing')
      //console.log(this.EditedStatusField,this.StatusField,this.EntityCollectionRef)
      if(this.NeedsRASync){
         this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.ConfigEntityID).collection('AllFields').doc('Status').set(this.EditedStatusField).then(rafield => {
            this.EntityCollectionRef.collection('AllFields').doc('Status').set(this.EditedStatusField).then(configfield => {
              this.CancelTableStateDialog()
            })
        })          
      }
      else{
        this.EntityCollectionRef.collection('AllFields').doc('Status').set(this.EditedStatusField).then(configfield => {
              this.CancelTableStateDialog()
            })
      }
      //TableStateDialog
      
    },
      EditTableState(){
      this.EditedStatusField = Object.assign({},this.StatusField)
      this.TableStateDialog = true
    },
        ActivateLiaisonTopicDialog(){
        this.LiaisonTopicDialog = true
      },
        ActivateEntityConfigView(item){
            //console.log('this.AppPluginsStored',this.AppPluginsStored)
            if(item.Pluginid && !this.AppPluginsStored.find(obj => obj.id === item.Pluginid)){
                this.PickPlugin(this.RAPlugins.find(obj => obj.id === item.Pluginid))
            }
            else{
                this.$store.commit('setEntityConfigView',item)
                // if(item.Route && item.RouteEntityID && this.CurrentEntity){
                //     this.$router.push(item.Route+'/'+this.CurrentEntity.id)
                // }
                // else{
                //   this.$store.commit('setEntityConfigView',item)  
                // }
                
            }
        },
        ToggleWorkflowBuilderDialog(){
            this.DeactivatePipelineworkflowdialog()
        },
        ActivatePipelineworkflowdialog(){
            this.WorkflowBuilderDialog = true
        },
        DeactivatePipelineworkflowdialog(){
            //this.WorkflowBuilderDialog = false
        },
        ActivateEditedRelationshipField(field){
            let ent = Object.assign({},this.SystemEntities.find(obj => obj.id === field.EntityID))
            this.EditedRelationshipField = Object.assign({},ent.AllFields.find(obj => obj.id === field.id))
            //console.log('this.EditedRelationshipField',this.EditedRelationshipField)
        },
        CancelEditedRelationshipField(){
            this.EditedRelationshipField = ''
        },
        LookupFieldDisplay(fieldobj){
            //return this.NewRelationshipFieldDisplay
            let string = ''
            if(fieldobj.EntityID){                 
                let ent = this.SystemEntities.find(obj => obj.id === fieldobj.EntityID)
                string = ent.DisplayName
            }
            string = string+' by '
            if(fieldobj && fieldobj.DisplayName){
                string = string+fieldobj.DisplayName
            }

            return string
        },
        UpdateField(field,prop,value){
            field[prop] = value
            if(!this.AddingNewField){
                
            }
            //console.log(field)
        },
        SaveNewRelationshipField(field){
            //console.log(field)
            let fieldobj = Object.assign({},this.FinalNewRelationshipField)
            fieldobj.id = fieldobj.Name
            //console.log(fieldobj)
            this.DBRef.collection('entities').doc(this.NewRelationshipField.EntityID).collection('AllFields').doc(fieldobj.id).set(fieldobj).then(newdoc => {
                this.CancelNewRelationshipField()
            })
        },
        UpdateRelated(field,RelatedBuildID){
            let array = []
            if(field.Type === 'Lookup'){
                array = this.SystemEntities
            }
            else{
                array = this.$store.state.SocialEntities
            }
            let entity = array.find(obj => obj.id === RelatedBuildID)
            if(entity){
                field.RelatedLocation = entity.SingleName
                field.RelatedRecord = entity.DisplayName
                field.LookupFieldName = entity.RecordPrimaryField.Name
            }
            if(!field.RelatedBuildID){
                field.RelatedBuildID = RelatedBuildID
            }
        },
        RefreshField(field){
        this.RefreshingNewField = true
        setTimeout(() => {
          this.RefreshingNewField = false
        }, 100);
      },
        CancelNewRelationshipField(){
            this.NewRelationshipFieldDialog = false
            this.NewRelationshipField = Object.assign({},this.DefaultNewRelationshipField)
        },
        AddNewRelationshipField(){
            this.NewRelationshipField = Object.assign({},this.DefaultNewRelationshipField)
            this.UpdateRelated(this.NewRelationshipField,this.CurrentEntity.id)
            this.NewRelationshipFieldDialog = true
        },
        CancelActiveEntity(){
            this.$store.commit('setPDFBuilderActive',false)
            if(this.EntityConfigView){
                this.$store.commit('setEntityConfigView','')
                this.$store.commit('setBuilderView','') 
            }
            else if(!this.EditedRelationshipField){
            this.ActiveEntity = ''
            this.StatusField = ''
            this.$store.commit('SetCurrentEntity','')
            }
            else{
                this.EditedRelationshipField = ''
            }
        },
        GetExporttoPDFFunctions(){
    //console.log(this.$route.meta.subcat.split(' ').join('_'))
    db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions').where('FunctionEntity','==',this.CurrentEntity.id).onSnapshot(res => {
        const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                this.ExporttoPDFFunctions.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
            })
    })
    },
        UpdateEntityConfig(prop,value){
            if(this.NeedsRASync){
            this.RAConfigDB.update({
               [prop]: value
            }).then(radoc => {
                this.EntityCollectionRef.update({
                    [prop]: value
                })
             })  
           }
           else{
            this.EntityCollectionRef.update({
                    [prop]: value
                })
           }
        },
        onceoffautonr(){
             let storordent = this.SystemEntities.find(obj => obj.Entity_Type.Name === 'Loan Order')
            let SONumberAuto = {
              id: storordent.id+'_Order_Number',
              Digits: 6,
              DisplayName: 'Order Number',
              Last_Numbers: [],
              Last_Number: 0,
              Last_Value: '',
              Name: 'Order_Number',
              NumberingString: '0',
              Type: 'AutoNumber Field',
              Suffixes: [],
              Prefixes: [
                {Prefix_Value: 'SO-',Type: 'Standard'}
              ]
            }
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('AutoNumbers').doc(SONumberAuto.id).set(SONumberAuto)
        },
        AddLoanPackage(){
            let newpack = {
                Name: 'Month End',
                Period: 1,
                Interest: 1,
                Description: `<div style="text-align: center;"><span style="font-size: 0.875rem; letter-spacing: 0.00714286em; font-family: var(--app-bodyfont) ;">A single instalment at the end of the transacting month</span></div>`
            }
            let colref = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc(this.CurrentEntity.id).collection('LoanPackages')
            let ref = colref.doc()
            let newdocid = ref.id
            colref.doc(newdocid).set(newpack).then(addeddoc => {
                
            })
        },
        TermsDocumentFileSelect(event,pack){
            let file = event.target.files[0]
            pack.UploadFile = file
            pack.FileName = file.name
        },
        UploadFileSelect(file,field,Record){
        console.log(file,field,Record)
        field.UploadFile = file
        field.FileName = file.name
        },
        ReceiveTermsDoc(payload){
            console.log(payload)
            let colref = payload.PassedObj.colref
            let fieldname = payload.PassedObj.fieldname
            let newdocid = payload.PassedObj.newdocid
            let pack = payload.PassedObj.pack
            delete pack.UploadFile
            delete pack.FileName
            pack[fieldname] = payload.UploadObject
            console.log(colref,pack,newdocid)
            this.FinalizeLoanPackageUpdate(colref,pack,newdocid)
        },
        SaveLoanPackage(pack){
            let colref = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc(this.CurrentEntity.id).collection('LoanPackages')
            let newdocid = ''
            if(pack.id){
                newdocid = pack.id
            }
            else{
                let ref = colref.doc()
                newdocid = ref.id
            }
            if(pack.UploadFile){
                console.log(pack)
                 let payload = {
                    file: pack.UploadFile,
                    filename: pack.FileName,
                    storepath: this.CurrentEntity.id+'/LoanPackageTerms/'+newdocid+'/'+new Date()+'/'+pack.FileName,
                    returnmethod: 'ReceiveTermsDoc',
                    PassedObj: {colref: colref,pack: pack,newdocid: newdocid,fieldname: 'TermsDocument'}
                }
                this.$store.commit('SetSocialItemInteractMethodProp',payload) 
                this.$store.commit('SetSocialItemInteractMethod','ProcessSingleFileUpload')
            }
            else{
                this.FinalizeLoanPackageUpdate(colref,pack,newdocid)               
            }
            
        },
        FinalizeLoanPackageUpdate(colref,pack,newdocid){
             colref.doc(newdocid).set(pack).then(addeddoc => {
                
            })
        },
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
            FieldObject[prop] = value
        },
        PercentageRules(){
            return [ 
                v => !!v || "This field is required",
                v => ( v && v >= 0 ) || "Loan should be above 0",
                v => ( v && v <= 1 ) || "It cannot be above 1",
            ]
        },
        UpdateDefaultSorting(){
           if(this.NeedsRASync){
            this.RAConfigDB.update({
                Default_Sort_Fields: this.ActiveEntity.Default_Sort_Fields
            }).then(radoc => {
                this.EntityCollectionRef.update({
                    Default_Sort_Fields: this.ActiveEntity.Default_Sort_Fields
                })
             })  
           }
           else{
            this.EntityCollectionRef.update({
                    Default_Sort_Fields: this.ActiveEntity.Default_Sort_Fields
                })
           }
        },
        UpdateDefaultBoard(){
           if(this.NeedsRASync){
            this.RAConfigDB.update({
                Default_Board: this.ActiveEntity.Default_Board
            }).then(radoc => {
                this.EntityCollectionRef.update({
                    Default_Board: this.ActiveEntity.Default_Board
                })
             })  
           }
           else{
            this.EntityCollectionRef.update({
                    Default_Board: this.ActiveEntity.Default_Board
                })
           }
        },
        NoPropsemit(MethodName){
            this[MethodName]()
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this[MethodName](prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this[MethodName](firstprop,secondprop)
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this[MethodName](firstprop,secondprop,thirdprop)
        },
        ResetTableOptionDialog(){
            this.subcolstep = 1
            
            this.SampleTableOption.Entityid = this.NewTableOption.Name.split(' ').join('_')
            this.SampleRecord.SubCollections = []
            this.SampleRecord.SubCollections.push(this.NewTableOption)
            if(this.SampleTableOption.OptionType === 'SubCollection'){
                this.SampleTableOption.EntitySource = 'SubCollection'
            }
            console.log(this.NewTableOption,this.SampleTableOption)
            setTimeout(() => {
                    this.subcolstep = 2
            }, 10);
        },
        CancelTableOptionDialog(){
            this.subcolstep = 1,
            this.NewTableOption = ''
            this.SampleTableOption = ''
            console.log(this.NewTableOption)
            //this.NewTableOption = ''
        },
        SelectedEntity(){
            if(this.NewTableOption){
                let entity = Object.assign({},this.NewTableOption)
                entity.AllFields = entity.Headers
                return entity
            }
            else{
                return this.CurrentEntity
            }
        },
        AddTableOption(opt){
            if(!opt.DisplayName){
             opt.DisplayName = opt.Name   
            }            
            opt.Name = opt.Name.split(' ').join('_')     
            console.log(opt)
            if(opt.Options){
            opt.PrimaryOptions = opt.Options
            opt.PrimaryOptionsInteger = opt.lastassignedinteger
            delete opt.Options
            delete opt.lastassignedinteger     
            this.NoPropsemit('CancelOptionSetDialog')
            }
            //.DocRegAdditionalFields.map?
            opt.MultipleFileUploadType = opt.OptionType
            opt.SectionType = opt.OptionType                
            this.FinalAddTableOption(opt)
            
        },
        FinalAddTableOption(opt){
            console.log(this.EntityCollectionRef,opt.ConfigCollection,opt)
            console.log(this.EntityCollectionRef.collection(opt.ConfigCollection).doc(opt.Name))
            console.log('CheckObjectforUndefined')
            this.CheckObjectforUndefined(1,opt,'('+opt.Name+')')
            if(!this.NeedsRASync){
                if(!opt.id){
                this.EntityCollectionRef.collection(opt.ConfigCollection).doc(opt.Name).set(opt)   
                }
                else{
                    if(opt.ConfigCollection === 'SubCollections'){
                        this.EntityCollectionRef.collection(opt.ConfigCollection).doc(opt.Name).set(opt)
                    }
                }
            }
            else{
                if(!opt.id){
                    this.RAConfigDB.collection(opt.ConfigCollection).doc(opt.Name).set(opt).then(radoc => {
                        this.EntityCollectionRef.collection(opt.ConfigCollection).doc(opt.Name).set(opt)
                    })  
                }
                else{
                    this.RAConfigDB.collection(opt.ConfigCollection).doc(opt.id).set(opt).then(radoc => {
                        this.EntityCollectionRef.collection(opt.ConfigCollection).doc(opt.id).set(opt)
                    })
                }
            }
            
        },
        ConfigureTable(tab){
            console.log(tab)
            if(tab.MultipleFileUploadType === 'SubCollection'){
                let opt = this.CurrentEntity.SubCollections.find(obj => obj.id === tab.id)
                this.ActivateTableOptionDialog(opt)
            }
            else if(tab.MultipleFileUploadType === 'Document Register'){
                let opt = this.CurrentEntity.DocumentRegisters.find(obj => obj.id === tab.id)
                this.ActivateTableOptionDialog(opt)
            }
            else if(tab.MultipleFileUploadType === 'Gallery'){
                let opt = this.CurrentEntity.Galleries.find(obj => obj.id === tab.id)
                this.ActivateTableOptionDialog(opt)
            }
        },
        ActivateTableOptionDialog(tableoption){
            this.NewTableOption = Object.assign({},tableoption)
            if(this.NewTableOption.MultipleFileUploadType === 'SubCollections' && this.NewTableOption.Type === 'Lookup' && this.NewTableOption.LookupBuilds){
                  this.NewTableOption.LookupBuilds = this.NewTableOption.LookupBuilds.map(build => {
                  let lookupbuild = this.SystemEntities.find(obj => obj.id === build.id)
                  console.log(build.id,this.NewTableOption,this.NewTableOption.LookupBuilds,lookupbuild)
                  if(lookupbuild){
                    build.AllFields = lookupbuild.AllFields
                    build.Entity_Type = lookupbuild.Entity_Type
                  }
                  return build
                  })
            }
             //.DocRegAdditionalFields.map?
            this.SampleTableOption = Object.assign({},tableoption)
            this.SampleTableOption.BGColor = {									
                    hex: '#ffffff',
                    hexa: '#ffffffff',									
            }
            console.log(this.SampleTableOption)
            this.subcolstep = 2
        },
        ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        InvokeConfigureAction(elmnt){
            //console.log(elmnt)
            if(this.CurrentEntity && elmnt.Check === 'Directories Setup'){
                let dir = this.DataNavlists.find(obj => obj.id === this.CurrentEntity.id)
                console.log(dir)
                this.$router.push(dir.Path)
            }
            else if(this.CurrentEntity && elmnt.Check === 'Directory Entries'){
                this.$router.push('/'+this.CurrentEntity.DisplayName.split(' ').join(''))
            }
            else if(this.CurrentEntity && elmnt.Check === 'BU Security Roles' || this.CurrentEntity && elmnt.Check === 'Custom Security Roles'){
                this.SecurityView = true
            }
            this.ScrolltoTop()
        },
        PrepQuickFieldSection(fields){
        return fields.map(field => {
            let fieldobj = Object.assign({},this.ConfigMasterEntity.AllFields.find(obj => obj.id === field))
            if(fieldobj.Type === 'Date' && typeof fieldobj.DateMenu === 'undefined'){
              fieldobj.DateMenu = false
              if(typeof this.ActiveEntity[fieldobj.Name] !== 'undefined' && typeof this.ActiveEntity[fieldobj.Name].toDate !== 'undefined'){
                let dateobject = this.ActiveEntity[fieldobj.Name].toDate()
                this.ActiveEntity[fieldobj.Name] = format(dateobject,'yyyy-MM-dd')
              }
            }
            return fieldobj
          })
      },
        PickPlugin(plug){
            this.$emit('PickPlugin',plug)
        },
        ToggleSecurityView(){
            this.SecurityView = !this.SecurityView
        },
        RAPluginsMatched(categorymatches){
        let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.RAPlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          //|| plug.Module_Category.Name === 'General'
        }).filter(item => {
           return item.Exclusively_as_Extension && item.Level.Name === 'App'
        }).filter(plug => {
          let newappplugins = this.ActiveSuiteApp.Plugins.map(plug => {
            return plug.id
          })
          //console.log(newappplugins)
          return !newappplugins.includes(plug.id)
        })
        },
        ActivateActiveEntity(ent){
            if(ent.Entity_Type.Name === 'Cash Loan'){
                if(ent.LoanPackages){
                   this.LoanPackages = ent.LoanPackages    
                }
            }
            this.ActiveEntity = ent
            this.StatusField = this.ActiveEntity.AllFields.find(obj => obj.id === 'Status')
            let Status = this.StatusField.DefaultOption
            let StatusReason = this.StatusField.DefaultLevel2Option
            this.RecordData = {
                Created_On: new Date(),   
                Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
                Created_Byid: this.userLoggedIn.id,
                Modified_On: new Date(),   
                Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
                Modified_Byid: this.userLoggedIn.id,
                Business_Unit: {id: 'Head_Office',Name: 'Head Office'},
                Business_Unitid: 'Head_Office',
                Owner: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
                Ownerid: this.userLoggedIn.id,
                Status: Status,
                Status_Reason: StatusReason,
                id: process.env.VUE_APP_RA_SYSTEM_ID
            }
           // this.$store.commit('setEntityConfigView','Main')
            this.$store.commit('SetCurrentEntity',ent)
        },
        CheckObjectforUndefined(lvl,obj,path){
      for(var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          let newpath = path+'/'+prop
          if(typeof obj[prop] === 'undefined'){
            console.log('LVL '+lvl+' prop UNDEFINED!!! '+newpath,obj)
          }
          else{
            if(!Array.isArray(obj[prop])){
              //console.log('LVL '+lvl+' prop OK '+newpath+' as ',obj[prop])
              //repeat this function somehow
              if(typeof obj[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,obj[prop],newpath)
              }
              
            }
            else{              
              //call array undefined check
              this.CheckArrayforUndefined(lvl-1+2,obj[prop],newpath)
            }
          }
        }
      }
    },
    CheckArrayforUndefined(lvl,obj,path){
      //console.log('checkingprop '+checkingprop+' on path '+path)
      obj.map(arrayrecord => {
        for(var prop in arrayrecord) {
          if (arrayrecord.hasOwnProperty(prop)) {
            let newpath = path+'/'+prop
            if(typeof arrayrecord[prop] === 'undefined'){
              console.log('LVL '+lvl+' array prop UNDEFINED!!! '+newpath,arrayrecord)
            }
            else{
              //console.log('LVL '+lvl+' prop OK '+newpath+' as ',arrayrecord[prop])
              if(!Array.isArray(arrayrecord[prop])){
                if(typeof arrayrecord[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                }
              }
              else{
                this.CheckArrayforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                //prop is array check for third prop
                // arrayrecord[prop].map(thirdrecord => {
                // })
              }
            }
          }
        }
      })
    },
    }
}
</script>

<style>

</style>



